<template>
  <div>
    <b-modal
      id="modal-transfer-detail"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Close')"
      ok-variant="primary"
      centered
      :title="$t('Transfer transaction detail')"
      size="lg"
      body-class="p-2"
      @hidden="resetModal()"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <label class="d-block h5 mb-1">{{ $t("Member details") }}</label>
      <b-table-simple
        responsive
        bordered
        table-class="table-narrow table-white-space table-highlight"
        class="mb-2"
      >
        <b-tbody>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Username") }}</b-td>
            <b-td>{{ transfer.username }}</b-td>
            <b-td class="table-td-header">{{ $t("Full Name") }}</b-td>
            <b-td>{{ transfer.fullName }}</b-td>
            <b-td class="table-td-header">{{ $t("Currency") }}</b-td>
            <b-td>{{ transfer.currency }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <label class="d-block h5 mb-1">{{ $t("Transfer detail") }}</label>
      <b-table-simple
        responsive
        bordered
        table-class="table-narrow table-white-space table-highlight"
        class="mb-2"
      >
        <b-tbody>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Transaction id") }}</b-td>
            <b-td>{{ transfer.transactionId }}</b-td>
            <b-td class="table-td-header">{{ $t("Amount") }}</b-td>
            <b-td>{{ transfer.amount }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Source") }}</b-td>
            <b-td>{{ transfer.source }}</b-td>
            <b-td class="table-td-header">{{ $t("Destination") }}</b-td>
            <b-td>{{ transfer.destination }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">Bonus Code</b-td>
            <b-td>{{ transfer.bonus_code }}</b-td>
            <b-td class="table-td-header">Bonus Amount</b-td>
            <b-td>{{ transfer.bonus_amount }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Created at") }}</b-td>
            <b-td>{{ transfer.created_at }}</b-td>
            <b-td class="table-td-header">{{ $t("Updated at") }}</b-td>
            <b-td>{{ transfer.updated_at }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t("Remark") }}</b-td>
            <b-td colspan="3">{{ transfer.reject_reason }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <txn-logs :transaction-id.sync="transactionId" />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BOverlay,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import {
  formatDateTime,
  resolveVBank,
  numberFormat,
  resolveCurrency,
} from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import TxnLogs from "@/views/payments/txn-logs/TxnLogs.vue";

export default {
  components: {
    TxnLogs,
    BOverlay,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BTbody,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    transferData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transactionId: null,
      transfer: {
        username: "",
        fullName: "",
        currency: "",
        transactionId: "",
        amount: "",
        bonus_code: "",
        bonus_amount: "",
        source: "",
        destination: "",
        created_at: "",
        updated_at: "",
        reject_reason: "",
        transaction_history: [],
      },
      show: false,
    };
  },
  watch: {
    transferData: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.transactionId = newVal.ref;
          this.transfer = {
            username: newVal.user ? newVal.user.username : "-",
            fullName: newVal.user ? newVal.user.name : "-",
            currency: newVal.user ? newVal.user.currency : "VND",
            transactionId: newVal.ref,
            amount: newVal.amount / 1000,
            bonus_code: newVal.bonus_code ? newVal.bonus_code.name : "",
            bonus_amount:
              typeof newVal.bonus_amount != "undefined"
                ? newVal.bonus_amount / 1000
                : "-",
            source: newVal.balance_type_from.name_en,
            destination: newVal.balance_type_to.name_en,
            created_at: formatDateTime(newVal.created_at).replace(
              ".000000Z",
              ""
            ),
            updated_at: formatDateTime(newVal.updated_at).replace(
              ".000000Z",
              ""
            ),
            reject_reason:
              typeof newVal.reject_reason != "undefined"
                ? newVal.reject_reason
                : "",
            transaction_history: [],
          };
        }
      },
    },
  },
  methods: {
    resetModal() {
      this.$emit("update:transferData", null);
      this.transfer = {};
    },
  },
  setup() {
    const resolveType = (type) => {
      if (type === 1) return "Credit";
      if (type === 2) return "Debit";
      return "None";
    };

    return {
      formatDateTime,
      resolveVBank,
      numberFormat,
      resolveCurrency,
      resolveType,
    };
  },
};
</script>
<style lang="scss" scoped>
.dark-layout .table-highlight .table-td-header {
  background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
  background-color: #f3f2f7;
}
</style>
>
